<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isProductPage?: boolean
    rating: number | null
  }>(),
  { isProductPage: false }
)

const formattedRating = computed(() => String(Math.round(10 * (props.rating as number)) / 10).replace('.', ','))
</script>

<template>
  <div class="rating-area flex items-center">
    <SvgIcon v-if="rating !== null" class="star" name="outlined/star" />

    <span
      v-if="rating !== null"
      class="rating"
      :class="{ 'is-product-page': isProductPage }"
    >{{ formattedRating }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.rating-area {
  color: $main-light-yellow-color;
  height: 20px;

  .star {
    font-size: 20px;
    margin-right: 4px;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  .rating {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }

    &.is-product-page {
      font-size: 16px;
    }
  }
}
</style>
